@import url('https://rsms.me/inter/inter.css');

/*html { font-family: 'Inter', sans-serif; }*/
/*@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}*/

/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
@tailwind components;

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
@tailwind utilities;


@layer components {
    .settings-button {
        @apply py-2 px-6 font-semibold rounded text-white text-lg;
    }

    .opt-song-item {
        content-visibility: auto;
        contain-intrinsic-size: 4rem;
    }
}

@layer utilities {
    .animation-stopped {
        animation-name: none;
    }
}
